import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'companyProfile',
    component: () => import('../view/companyProfile.vue')
  },
  {
    path: '/brand-introduction',
    name: 'brandIntroduction',
    component: () => import('../view/brandIntroduction.vue'),
    children: [{
      path: 'qingji',
      name: 'qingji',
      component: () => import('../view/brands/qingji.vue')
    },{
      path: 'qingjiMini',
      name: 'qingjiMini',
      component: () => import('../view/brands/qingjiMini.vue')
    },{
      path: 'yunFei',
      name: 'yunFei',
      component: () => import('../view/brands/yunFei.vue')
    },{
      path: 'yunKuan',
      name: 'yunKuan',
      component: () => import('../view/brands/yunKuan.vue')
    },{
      path: 'openVine',
      name: 'openVine',
      component: () => import('../view/brands/openVine.vue')
    },{
      path: 'aimoHotel',
      name: 'aimoHotel',
      component: () => import('../view/brands/aimoHotel.vue')
    },{
      path: 'aimoApartment',
      name: 'aimoApartment',
      component: () => import('../view/brands/aimoApartment.vue')
    },{
      path: '99YouxuanHotel',
      name: '99YouxuanHotel',
      component: () => import('../view/brands/99YouxuanHotel.vue')
    },{
      path: '99HotelChain',
      name: '99HotelChain',
      component: () => import('../view/brands/99HotelChain.vue')
    },{
      path: '99XinbiaoHotel',
      name: '99XinbiaoHotel',
      component: () => import('../view/brands/99XinbiaoHotel.vue')
    }]
  },
  {
    path: '/book-hotels',
    name: 'bookHotels',
    component: () => import('../view/bookHotels.vue')
  },
  {
    path: '/about-joining',
    name: 'aboutJoining',
    component: () => import('../view/aboutJoining.vue')
  },
  {
    path: '/contact-gongsheng',
    name: 'contactGongSheng',
    component: () => import('../view/contactGongSheng.vue')
  }
];
const router = createRouter({
  history: createWebHashHistory(),
  routes
});
export default router;