import { createApp } from 'vue'
import './style/index.scss'
import App from './App.vue'
import router from './router';
import 'wow.js/css/libs/animate.css'
import 'animate.css';
import { createHead } from '@vueuse/head';
import { registerGlobalComponents } from './components/globalComponents.ts';
import './utils/rem'; 

const head = createHead();
const app = createApp(App);
registerGlobalComponents(app);
app.use(head)
app.use(router);
app.mount('#app');

